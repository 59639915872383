import {createContext} from 'react';
import {
    SLIDE1, SLIDE2, SLIDE3, SLIDE4, SLIDE5, SLIDE6, SLIDE7, SLIDE8, SLIDE9, PROJECT1MAIN, PROJECT1TWO, PROJECT1THREE,
    PROJECT1FOUR, PROJECT1FIFE, PROJECT1SIX, PROJECT1PLANE, PROJECT2MAIN, PROJECT2SLIDE, PROJECT2SLIDE2,
    PROJECT2SLIDE3, PROJECT2SLIDE4, PROJECT2SLIDE5, PROJECT2SLIDE6, PROJECT3MAIN, PROJECT3SLIDE, PROJECT3SLIDE2,
    PROJECT3SLIDE3, PROJECT3SLIDE4, PROJECT3SLIDE5, PROJECT3SLIDE6, PROJECT3SLIDE7, PROJECT3SLIDE8,
    PROJECT3SLIDE9, PROJECT4MAIN, PROJECT4SLIDE, PROJECT4SLIDE2, PROJECT5MAIN, PROJECT5SLIDE, PROJECT5SLIDE2,
    PROJECT5SLIDE3, PROJECT5SLIDE4, PROJECT5SLIDE5, PROJECT5SLIDE6, PROJECT5SLIDE7, PROJECT5SLIDE8, PROJECT5SLIDE9,
    PROJECT6MAIN, PROJECT6SLIDE, PROJECT6SLIDE2, PROJECT6SLIDE3, PROJECT6SLIDE4, PROJECT7MAIN, PROJECT7SLIDE, PROJECT7SLIDE2,
    PROJECT7SLIDE3, PROJECT7SLIDE4, PROJECT7SLIDE5, PROJECT8MAIN, PROJECT8SLIDE, PROJECT8SLIDE2, PROJECT8SLIDE3,
    PROJECT8SLIDE4, PROJECT8SLIDE5, PROJECT8SLIDE6, PROJECT8SLIDE7, PROJECT8SLIDE8, PROJECT8SLIDE9, PROJECT8SLIDE10,
    PROJECT8SLIDE11, PROJECT8SLIDE12, PROJECT9MAIN, PROJECT9SLIDE, PROJECT9SLIDE2, PROJECT9SLIDE3, PROJECT9SLIDE4,
    PROJECT9SLIDE5, PROJECT9SLIDE6, PROJECT9SLIDE7, PROJECT10MAIN, PROJECT10SLIDE, PROJECT10SLIDE2, PROJECT10SLIDE3,
    PROJECT10SLIDE4, PROJECT10SLIDE5, PROJECT10SLIDE6, PROJECT10SLIDE7, PROJECT10SLIDE8, PROJECT10SLIDE9,
    PROJECT10SLIDE10, PROJECT10SLIDE11, PROJECT10SLIDE12, PROJECT10SLIDE13, PROJECT10SLIDE14, PROJECT10SLIDE15,
    PROJECT10SLIDE16, PROJECT10SLIDE17, PROJECT10SLIDE18, PROJECT11MAIN, PROJECT11SLIDE, PROJECT11SLIDE2, PROJECT11SLIDE3,
    PROJECT11SLIDE4, PROJECT11SLIDE5, PROJECT11SLIDE6, PROJECT11SLIDE7, PROJECT11SLIDE8, PROJECT11SLIDE9,
    PROJECT11SLIDE10, PROJECT11SLIDE11, PROJECT11SLIDE12, PROJECT11SLIDE13, PROJECT11SLIDE14, PROJECT11SLIDE15,
    PROJECT11SLIDE16, PROJECT11SLIDE17, PROJECT11SLIDE18,
} from "../assets/images_path";

const Context = createContext();

const ContextProvider = ({children}) => {

    const sliders = [
        {
            id: 1,
            img: SLIDE1
        },
        {
            id: 2,
            img: SLIDE2
        },
        {
            id: 3,
            img: SLIDE3
        },
        {
            id: 4,
            img: SLIDE4
        },
        {
            id: 5,
            img: SLIDE5
        },
        {
            id: 6,
            img: SLIDE6
        },
        {
            id: 7,
            img: SLIDE7
        },
        {
            id: 8,
            img: SLIDE8
        },
        {
            id: 9,
            img: SLIDE9
        },
        // {
        //     id: 10,
        //     img: tenSlide
        // },
    ]

    const projects = [
        {
            id: 1,
            mainImage: PROJECT1MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT1TWO
                },
                {
                    id: 2,
                    img: PROJECT1THREE
                },
                {
                    id: 3,
                    img: PROJECT1FOUR
                },
                {
                    id: 4,
                    img: PROJECT1FIFE
                },
                {
                    id: 5,
                    img: PROJECT1SIX
                },
            ],
            plane: PROJECT1PLANE,
            name: 'Интерьер дизайн',
            workType: "Интерьер дизайн",
            type: "Интерьер дизайн",
            date: "2023",
            square: "253 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 2,
            mainImage: PROJECT2MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT2SLIDE
                },
                {
                    id: 2,
                    img: PROJECT2SLIDE2
                },
                {
                    id: 3,
                    img: PROJECT2SLIDE3
                },
                {
                    id: 4,
                    img: PROJECT2SLIDE4
                },
                {
                    id: 5,
                    img: PROJECT2SLIDE5
                },
                {
                    id: 6,
                    img: PROJECT2SLIDE6
                },
            ],
            plane: PROJECT1PLANE,
            name: 'Интерьер дизайн',
            workType: "Интерьер дизайн",
            type: "Интерьер дизайн",
            date: "2023",
            square: "253 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 3,
            mainImage: PROJECT3MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT3SLIDE
                },
                {
                    id: 2,
                    img: PROJECT3SLIDE2
                },
                {
                    id: 3,
                    img: PROJECT3SLIDE3
                },
                {
                    id: 4,
                    img: PROJECT3SLIDE4
                },
                {
                    id: 5,
                    img: PROJECT3SLIDE5
                },
                {
                    id: 6,
                    img: PROJECT3SLIDE6
                },
                {
                    id: 7,
                    img: PROJECT3SLIDE7
                },
                {
                    id: 8,
                    img: PROJECT3SLIDE8
                },
                {
                    id: 9,
                    img: PROJECT3SLIDE9
                }
            ],
            plane: PROJECT1PLANE,
            name: 'Интерьер дизайн подвала',
            workType: "Интерьер дизайн",
            type: "Интерьер дизайн",
            date: "2023",
            square: "253 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 4,
            mainImage: PROJECT4MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT4SLIDE
                },
                {
                    id: 2,
                    img: PROJECT4SLIDE2
                },
            ],
            plane: PROJECT1PLANE,
            name: 'Заправка, 122 м²',
            workType: "Проектирование",
            type: "Заправка",
            date: "2023",
            square: "122 м²",
            place: "Ташкент, Алайский",
            status: "В стадии реализации",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 5,
            mainImage: PROJECT5MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT5SLIDE
                },
                {
                    id: 2,
                    img: PROJECT5SLIDE2
                },
                {
                    id: 3,
                    img: PROJECT5SLIDE3
                },
                {
                    id: 4,
                    img: PROJECT5SLIDE4
                },
                {
                    id: 5,
                    img: PROJECT5SLIDE5
                },
                {
                    id: 6,
                    img: PROJECT5SLIDE6
                },
                {
                    id: 7,
                    img: PROJECT5SLIDE7
                },
                {
                    id: 8,
                    img: PROJECT5SLIDE8
                },
                {
                    id: 9,
                    img: PROJECT5SLIDE9
                }
            ],
            plane: PROJECT1PLANE,
            name: 'Коттеджи в Сочи',
            workType: "Проектирование",
            type: "Коттеджи в Сочи",
            date: "2016",
            square: "626, 47 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 6,
            mainImage: PROJECT6MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT6SLIDE
                },
                {
                    id: 2,
                    img: PROJECT6SLIDE2
                },
                {
                    id: 3,
                    img: PROJECT6SLIDE3
                },
                {
                    id: 4,
                    img: PROJECT6SLIDE4
                },
            ],
            plane: PROJECT1PLANE,
            name: 'Коттедж в Пскове',
            workType: "Проектирование",
            type: "Загородный дом",
            date: "2022",
            square: "213 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 7,
            mainImage: PROJECT7MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT7SLIDE
                },
                {
                    id: 2,
                    img: PROJECT7SLIDE2
                },
                {
                    id: 3,
                    img: PROJECT7SLIDE3
                },
                {
                    id: 4,
                    img: PROJECT7SLIDE4
                },
                {
                    id: 5,
                    img: PROJECT7SLIDE5
                },
            ],
            plane: PROJECT1PLANE,
            name: 'Офис креативного агентства Agency X',
            workType: "Проектирование",
            type: "Офисное пространство",
            date: "2021",
            square: "135 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 8,
            mainImage: PROJECT8MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT8SLIDE
                },
                {
                    id: 2,
                    img: PROJECT8SLIDE2
                },
                {
                    id: 3,
                    img: PROJECT8SLIDE3
                },
                {
                    id: 4,
                    img: PROJECT8SLIDE4
                },
                {
                    id: 5,
                    img: PROJECT8SLIDE5
                },
                {
                    id: 6,
                    img: PROJECT8SLIDE6
                },
                {
                    id: 7,
                    img: PROJECT8SLIDE7
                },
                {
                    id: 8,
                    img: PROJECT8SLIDE8
                },
                {
                    id: 9,
                    img: PROJECT8SLIDE9
                },
                {
                    id: 10,
                    img: PROJECT8SLIDE10
                },
                {
                    id: 11,
                    img: PROJECT8SLIDE11
                },
                {
                    id: 12,
                    img: PROJECT8SLIDE12
                }
            ],
            plane: PROJECT1PLANE,
            name: 'Интерьер дизайн',
            workType: "Интерьер дизайн",
            type: "Интерьер дизайн",
            date: "2023",
            square: "253 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 9,
            mainImage: PROJECT9MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT9SLIDE
                },
                {
                    id: 2,
                    img: PROJECT9SLIDE2
                },
                {
                    id: 3,
                    img: PROJECT9SLIDE3
                },
                {
                    id: 4,
                    img: PROJECT9SLIDE4
                },
                {
                    id: 5,
                    img: PROJECT9SLIDE5
                },
                {
                    id: 6,
                    img: PROJECT9SLIDE6
                },
                {
                    id: 7,
                    img: PROJECT9SLIDE7
                },
            ],
            plane: PROJECT1PLANE,
            name: 'Интерьер дизайн',
            workType: "Интерьер дизайн",
            type: "Интерьер дизайн",
            date: "2023",
            square: "253 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
        {
            id: 10,
            mainImage: PROJECT10MAIN,
            images: [
                {
                    id: 1,
                    img: PROJECT10SLIDE
                },
                {
                    id: 2,
                    img: PROJECT10SLIDE2
                },
                {
                    id: 3,
                    img: PROJECT10SLIDE3
                },
                {
                    id: 4,
                    img: PROJECT10SLIDE4
                },
                {
                    id: 5,
                    img: PROJECT10SLIDE5
                },
                {
                    id: 6,
                    img: PROJECT10SLIDE6
                },
                {
                    id: 7,
                    img: PROJECT10SLIDE7
                },
                {
                    id: 8,
                    img: PROJECT10SLIDE8
                },
                {
                    id: 9,
                    img: PROJECT10SLIDE9
                },
                {
                    id: 10,
                    img: PROJECT10SLIDE10
                },
                {
                    id: 11,
                    img: PROJECT10SLIDE11
                },
                {
                    id: 12,
                    img: PROJECT10SLIDE12
                },
                {
                    id: 13,
                    img: PROJECT10SLIDE13
                },
                {
                    id: 14,
                    img: PROJECT10SLIDE14
                },
                {
                    id: 15,
                    img: PROJECT10SLIDE15
                },
                {
                    id: 16,
                    img: PROJECT10SLIDE16
                },
                {
                    id: 17,
                    img: PROJECT10SLIDE17
                },
                {
                    id: 18,
                    img: PROJECT10SLIDE18
                }
            ],
            plane: PROJECT1PLANE,
            name: 'Интерьер дизайн',
            workType: "Интерьер",
            type: "Интерьер дизайн",
            date: "2023",
            square: "253 м²",
            place: "Ташкент, Алайский",
            status: "Реализован",
            team: "Наврузов Зиёдулло"
        },
    ];

    const steps = [
        {
            id: 1,
            title: "знакомство",
            description: "На первой встрече, лично или онлайн, расскажем о нашей компании, проектных возможностях, методах разработки и управления проектами, покажем интересные кейсы и ответим на любые вопросы.",
            number: "01"
        },
        {
            id: 2,
            title: "экспертиза и смета",
            description: "Заполним бриф и подробно изучим всю документацию по проекту. Предложим несколько оптимальных решений Вашей задачи и составим подробную смету проектных работ.",
            number: "02"
        },
        {
            id: 3,
            title: "договор",
            description: "Заключим договор с удобной для Вас формой оплаты и точным срок окончания всех работ. Составим график встреч для согласования предварительных этапов.",
            number: "03"
        },
        {
            id: 4,
            title: "техническое задание",
            description: "Определим Ваши функциональные и эстетические пожелания, бюджет на реализацию и другие важные условия. Зафиксируем их в техническом задании на основе которого разработаем проект.",
            number: "04"
        },
        {
            id: 5,
            title: "разработка проекта",
            description: "Работать будем поэтапно и строго по ТЗ. Результат каждого этапа сначала согласуем с Вами и только потом приступим к следующему. При необходимости внесем правки и улучшения.",
            number: "05"
        },
        {
            id: 6,
            title: "получение разрешений",
            description: "Ваш проект будет сооствествовать ГОСТам, СНиПам и современным технологиям. Мы сами получим все разрешения на строительство, перепланировку, наружную рекламу и т.д.",
            number: "06"
        },
        {
            id: 7,
            title: "строительство и отделка",
            description: "Составим детальную смету и проведем тендер среди проверенных подрядчиков. Совместно с Вами выберем лучшего исполнителя по цене, срокам и гарантиям качества. ",
            number: "07"
        }
    ]

    const services = [
        {
            id: 1,
            title: "архитектурное проектирование",
            description: "Проектируем и строим современные коттеджи и коммерческие здания.",
            description2: "От создания авторской концепции до разработки всех конструктивных и инженерных разделов с гарантией реализации. Работаем с объектами культурного наследия ОКН.",
            price: "проекты от 3 000 000 sum",
            button: "рассчитать стоимость"
        },
        {
            id: 2,
            title: "дизайн интерьера",
            description: "Создаем и реализуем премиальные интерьеры загородных домов, квартир, ресторанов, отелей, офисов и общественных пространств.",
            description2: "Работаем в современных стилях с учетом бюджета заказчика.",
            price: "проекты от 5 000 000 sum",
            button: "рассчитать стоимость"
        },
        {
            id: 3,
            title: "ландшафтный дизайн",
            description: "Создаем лаконичные, функциональные и запоминающиеся проекты для загородных домов и общественных городских пространств.",
            description2: "Придерживаемся принципов Органической архитектуры и Зеленого строительства.",
            price: "проекты от 3 000 000 sum",
            button: "рассчитать стоимость"
        },
        {
            id: 4,
            title: " управление строительством",
            description: "Комплексный подход к реализации наших проектов:  комплектация,  авторский надзор,  технический надзор,  юридическое сопровождение",
            description2: "Гарантируем законность, точность и своевременность реализации проектных решений.",
            price: "бесплатно при комплексном заказе",
            button: "узнать подробности"
        }
    ]

    const vacancies = [
        {
            id: 1,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        },
        {
            id: 2,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        },
        {
            id: 3,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        },
        {
            id: 4,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        },
        {
            id: 5,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        },
        {
            id: 6,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        },
        {
            id: 7,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        },
        {
            id: 8,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        },
        {
            id: 9,
            position: "Ведущий архитектор",
            city: "Санкт-Петербург",
            address: "Санкт-Петербург, ул. Социалистическая, 21",
            workTime: "полная занятость",
            experience: "3-6 лет",
            salary: "от 60 000 ₽",
            salaryType: "на руки"
        }
    ]

    return (
        <Context.Provider
            value={{sliders, projects, steps, services, vacancies}}
        >
            {children}
        </Context.Provider>
    );
};

export {Context, ContextProvider};
